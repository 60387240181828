import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function Lsa() {
  return <Seo title={sections.Lsa.title} />;
}

export default Lsa;
